import React, { useReducer, createContext } from 'react';

const initialState = {
  typeSelected: false,
  isBusinessLoan: false,
  customerDetails: {},
  customerDetailsFilled: {},
  propertyInfo: {},
  propertyInfoFilled: {},
  currentBusinessStep: 1,
  currentHomeStep: 1,
  homeLoanOptions: {},
  businessLoanOptions: {},
  businessFinanceFor: {},
  businessFinanceForFilled: {},
  businessInfo: {},
  businessInfoFilled: {},
  userAgrees: false,
  termsOpen: false,
  marketingConsent: false,
};

export const FormContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'resetAllFields':
      return {
        typeSelected: false,
        isBusinessLoan: false,
        customerDetails: {},
        customerDetailsFilled: {},
        propertyInfo: {},
        propertyInfoFilled: {},
        currentBusinessStep: 1,
        currentHomeStep: 1,
        homeLoanOptions: {},
        businessLoanOptions: {},
        businessFinanceFor: {},
        businessFinanceForFilled: {},
        businessInfo: {},
        businessInfoFilled: {},
        userAgrees: false,
        termsOpen: false,
        marketingConsent: false,
      };
    case 'updateIsBusinessLoan':
      return {
        ...state,
        isBusinessLoan: action.payload,
      };
    case 'updateTypeSelected':
      return {
        ...state,
        typeSelected: action.payload,
      };
    case 'updateCurrentBusinessStep':
      return {
        ...state,
        currentBusinessStep: action.payload,
      };
    case 'updateCurrentHomeStep':
      return {
        ...state,
        currentHomeStep: action.payload,
      };
    case 'updateCustomerDetails':
      return {
        ...state,
        customerDetails: action.payload,
      };
    case 'updatePropertyInfo':
      return {
        ...state,
        propertyInfo: action.payload,
      };
    case 'updatePropertyInfoFilled':
      return {
        ...state,
        propertyInfoFilled: action.payload,
      };
    case 'updateHomeLoanOptions':
      return {
        ...state,
        homeLoanOptions: action.payload,
      };
    case 'updateBusinessFinanceFor':
      return {
        ...state,
        businessFinanceFor: action.payload,
      };
    case 'updateBusinessFinanceForFilled':
      return {
        ...state,
        businessFinanceForFilled: action.payload,
      };
    case 'updateBusinessInfo':
      return {
        ...state,
        businessInfo: action.payload,
      };
    case 'updateBusinessInfoFilled':
      return {
        ...state,
        businessInfoFilled: action.payload,
      };
    case 'updateBusinessLoanOptions':
      return {
        ...state,
        businessLoanOptions: action.payload,
      };

    case 'updateCustomerDetailsFilled':
      return {
        ...state,
        customerDetailsFilled: action.payload,
      };
    case 'updateUserAgrees':
      return {
        ...state,
        userAgrees: action.payload,
      };
    case 'updateTermsOpen':
      return {
        ...state,
        termsOpen: action.payload,
      };
    case 'updateMarketingConsent':
      return {
        ...state,
        marketingConsent: action.payload,
      };

    default:
      throw new Error('Invalid action type');
  }
};

const FormStateProvider = ({ children }) => {
  const [formState, dispatch] = useReducer(reducer, initialState);
  return (
    <FormContext.Provider value={{ formState, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};

const useFormContext = () => {
  const context = React.useContext(FormContext);
  if (context === undefined) {
    throw new Error(`useSiteContext must be used within a SiteProvider`);
  }
  return context;
};

export { FormStateProvider, useFormContext };
